<template>
  <button v-if="!isSubscribed" class=" ms-2 btn btn-outline-info" @click="subscribeToNotifications">Підписатися на сповіщення</button>
  <button v-else class=" ms-2 btn btn-outline-info" @click="unsubscribeUser">Відписатися від сповіщень</button>
</template>

<script>
export default {
  name: "SubscribeNotification",
  data() {
    return {
      isSubscribed: false
    };
  },
  methods: {
    async subscribeToNotifications() {
      try {
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
           new Error('Дозвіл на сповіщення не надано');
        }

        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(process.env.VUE_APP_WEB_PUSH_PUBLIC_KEY)
        });

        this.sendSubscriptionToServer(subscription);

      } catch (error) {
        console.error('Помилка при підписці на сповіщення:', error);
      }
    },
    async unsubscribeUser() {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
        console.log('Підписка відмінена.');
        this.isSubscribed = false;
        const subscriptionId = localStorage.getItem('webPushSubscription');
        if (subscriptionId) {
          this.$http.delete(`/api/notifications/subscription/${subscriptionId}`).then(
              result => {
                const data = result.data;
                console.log(data)
                localStorage.removeItem('webPushSubscription');
              }
          );
        }
      }
    },
    sendSubscriptionToServer(subscription) {
      const userId = this.$store.state.user.id;
      this.$http.post('/api/notifications/subscribe', {userId, subscription}).then(
          result => {
            const data = result.data;
            localStorage.setItem('webPushSubscription', data._id);
            this.isSubscribed = true;
          }
      );
    },
    async checkSubscription() {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      this.isSubscribed = !!subscription;
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }
  },
  mounted() {
    this.checkSubscription()
    console.log(this.isSubscribed,process.env.VUE_APP_WEB_PUSH_PUBLIC_KEY)
  }
};
</script>
