<script>
import VueMultiselect from 'vue-multiselect'
import FancyBoxGallery from "@/components/parts/FancyBoxGallery.vue";
import StatusSelector from "@/components/parts/StatusSelector.vue";
import FilmCutsEditor from "@/components/parts/FilmCutsEditor.vue";
import texts from "../../.././texsts.js";
import helpers from "../../.././helpers.js";
import ClientForm from "@/components/forms/ClientForm.vue";

export default {
  name: "OrderForm",
  components: {ClientForm, VueMultiselect, FancyBoxGallery, StatusSelector, FilmCutsEditor},
  emits: ['SubmitOrder'],
  props: {
    orderId: {
      type: String,
    }
  },
  data: function () {
    return {
      orderData: {
        title: "",
        description: "",
        status: "active",
        isUrgent: false,
        clientId: "",
        managerId: "",
        draftsmanId: "",
        imagesId: [],
        filesId: [],
        filmCuts: [],
        filmWidth: 0,
        filmHeight: 0,
        filmSize: 0,
        carBrand: "",
        carModel: "",

      },
      selected: null,
      isUpdated: false,
      addClient: false,
      value: {},
      tempClients: [],
      tempUsers: [],
      uploadUrl: '/api/upload',
      fileRecords: [],
      imageRecords: [],
      uploadHeaders: {'Authorization': this.$store.getters.userToken},
      fileRecordsForUpload: [],
      clientData: {},
      managerData: {},
      updateData: {},
    };
  },
  methods: {
    toggleAddClient() {
      this.addClient = !this.addClient;
    },
    submitOrder() {
      this.getFiles();
      this.orderData.managerId = this.orderManager;
      this.orderData.clientId = this.clientData?._id;
      this.orderData.updateData = this.updateData
      if (this.orderId) {
        this.orderData._id = this.orderId
      }
      this.$emit('submitOrder', this.orderData);
      this.resetOrderData();
    },
    async asyncFind(query) {
      const clients = await this.$http.get('/api/clients/',{
        params: {
          name:query
        }
      });
      this.tempClients = clients.data.clientData;
    },
    async asyncFindUsers(query) {
      const users = await this.$http.get('/api/users/', {
        params: {
          type: "manager",
          name: query
        }
      });
      this.tempUsers = users.data.data;
    },
    getFiles() {
      this.fileRecords.map(file => {
        this.orderData.filesId.push(file.id)
      })
      this.imageRecords.map(file => {
        this.orderData.imagesId.push(file.id)
      })
    },
    imageFilesUpload: function (fileRecordsNewlySelected) {
      let counter = 0
      fileRecordsNewlySelected.forEach(file => {
        this.imageRecords.push({
          id: file.data._id,
          type: file.data.type,
          name: file.data.fileName,
          url: "/" + file.data.path,
          size: file.data.size
        })
        counter++;
      })

      this.mapChanges("image", `Заванатжено картинок ${counter}`)
    },
    filesRecordsUpload: function (fileRecordsNewlySelected) {
      let counter = 0
      fileRecordsNewlySelected.forEach(file => {
        this.fileRecords.push({
          id: file.data._id,
          type: file.data.type,
          name: file.data.fileName,
          url: "/" + file.data.path,
          size: file.data.size
        })
        counter++;
      })
      this.mapChanges("file", `Заванатжено файлів ${counter}`)
    },
    onBeforeDelete: function (fileRecord, type = 'image') {

      this.$swal.fire({
        title: texts.titles.alert,
        text: texts.asks.delete_file,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: texts.buttons.delete,
        cancelButtonText: texts.buttons.cancel
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === 'image') {
            this.imageRecords = this.imageRecords.filter((image) => {
              return image.id !== fileRecord.raw.id
            })
          } else {
            this.fileRecords = this.fileRecords.filter((file) => {
              return file.id !== fileRecord.raw.id
            })
          }

          this.$http.delete("/api/upload/" + fileRecord.raw.id).then(r => {
            this.$swal.fire({
              icon: 'success',
              title: texts.titles.success,
              text: helpers.customReplace("{name}", r.data.fileName, texts.messages.file_deleted)
            })

          }).catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: texts.titles.error,
              text: err?.response?.data?.message

            })
          })
        } else {

          if (type === 'image') {
            if (this.$refs.VueFileAgentNext) {
              this.$refs.VueFileAgentNext.filenameChanged(fileRecord);
            }
          } else {
            if (this.$refs.fileAgent) {
              this.$refs.fileAgent.filenameChanged(fileRecord);
            }
          }
        }
      })

    },
    customDelete: function (fileRecord) {
      return fileRecord;
    },
    resetOrderData() {
      this.orderData = {
        title: "",
        description: "",
        status: "active",
        isUrgent: false,
        clientId: "",
        managerId: this.$store.getters.userData.id,
        draftsmanId: "",
        imagesId: [],
        filesId: [],
        filmCuts: [],
        filmWidth: 0,
        filmHeight: 0,
        filmSize: 0,
        carBrand: "",
        carModel: "",
      };
      this.fileRecords = [];
      this.imageRecords = [];
      this.clientData = {};
      this.fileRecordsForUpload = []

    },
    mapChanges(type, changes) {

      switch (type) {
        case 'image':
          this.updateData.image = changes;
          break;
        case 'file':
          this.updateData.file = changes;
          break;
        case 'status':
          this.updateData.status = changes;
          break;
        case 'title':
          this.updateData.title = `${this.orderData.title}`;
          break;
        case 'description':
          this.updateData.description = `${this.orderData.description}`;
          break;
        case 'carBrand':
          this.updateData.carBrand = `${this.orderData.carBrand}`;
          break;
        case 'carModel':
          this.updateData.carModel = `${this.orderData.carModel}`;
          break;
        case 'isUrgent':
          this.updateData.isUrgent = `Замовлення термінове ${this.orderData.isUrgent}`;
          break;
        case 'clientId':
          this.updateData.clientId = `${this.clientData.name}`;
          break;
        case 'managerId':
          this.updateData.managerId = `${this.managerData._id}`;
          this.updateData.managerName = `${changes}`;
          break;
        case 'cuts':
          if (this.$store.getters.isDraftsman) {
            this.updateData.cuts = JSON.stringify(changes);
            this.$http.put("/api/orders/cuts/" + this.orderId, changes).catch(e => {
              console.error(e)
            })
          }
          break;
        default:
          break;
      }
    }
  },
  mounted() {

    if (this.orderId) {

      this.$http.get("/api/orders/" + this.orderId)
          .then((res) => {
            this.orderData = res.data
            if (this.orderData.clientId) {
              this.$http.get('/api/clients/' + this.orderData.clientId)
                  .then(response => {
                    this.clientData = response.data
                  })
            }

            if (this.orderData.managerId) {
              this.$http.get('/api/user/' + this.orderData.managerId)
                  .then(response => {
                    this.managerData = response.data
                  })
            }

            if (this.orderData.imagesId.length > 0) {
              this.$http.get('/api/files/', {params: {ids: this.orderData.imagesId}}).then(response => {
                this.imageRecords = response.data.map(file => {
                  return {
                    id: file._id,
                    name: file.fileName,
                    type: file.type,
                    url: file.path,
                    size: file.size
                  }
                })
              }).catch(e => {
                console.error(e)
              })
            }

            if (this.orderData.filesId.length > 0) {
              this.$http.get('/api/files/', {params: {ids: this.orderData.filesId}}).then(response => {
                this.fileRecords = response.data.map(file => {
                  return {
                    id: file._id,
                    name: file.fileName,
                    type: file.type,
                    url: file.path,
                    size: file.size
                  }
                })
              }).catch(e => {
                console.error(e)
              })
            }
          })
    }
  },
  computed: {
    orderManager: function () {
      if (!this.managerData._id && !this.orderId) {
        return this.$store.getters.userData.id
      }
      return this.managerData._id
    },
  }
}
</script>

<template>
  <div class="order">
    <h4 v-if="orderData.humanId" class="py-3">
      Дані замовлення {{ userRole }}
      <span> ID: <strong>{{ orderData.humanId }}</strong> </span>
    </h4>
    <form action="">
      <div class="input-group mb-3">
        <span class="input-group-text">@</span>
        <input type="text" class="form-control"
               placeholder="Марка та модель машини"
               v-model="orderData.title"
               @change="mapChanges('title', $event.target.value)"
        >
      </div>

      <StatusSelector @mapChanges="mapChanges" v-if="this.orderId" v-model="orderData.status"/>

      <div class="input-group mb-3">
        <span class="input-group-text">Нотатки</span>
        <textarea class="form-control"
                  rows="3"
                  v-model="orderData.description"
                  @change="mapChanges('description', $event.target.value)"
                  placeholder="Опис замовлення">
      </textarea>

      </div>
      <div class="input-group mb-3 d-none">
        <span class="input-group-text">@</span>
        <input type="text" class="form-control"
               placeholder="Марка машини"
               v-model="orderData.carBrand"
               @change="mapChanges('carBrand', $event.target.value)"
        >
      </div>
      <div class="input-group mb-3 d-none">
        <span class="input-group-text">@</span>
        <input type="text" class="form-control"
               placeholder="Модель машини"
               @change="mapChanges('carModel', $event.target.value)"
               v-model="orderData.carModel">
      </div>

      <div class="form-check form-check-inline my-2">
        <input class="form-check-input"
               type="checkbox"
               id="isActive"
               @change="mapChanges('isUrgent', $event.target.checked)"
               v-model="orderData.isUrgent">
        <label class="form-check-label" for="isActive">Замовлення термінове</label>
      </div>

      <div class="input-group">
        <label class="form-check-label my-2">Клієнт</label>
        <div class="row w-100 px-0 mx-0 align-items-center justify-content-center">
          <div class="col-md-11 px-0">
            <VueMultiselect
                v-model="this.clientData"
                :options="tempClients"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                @select="mapChanges('clientId', $event.name)"
                @search-change="asyncFind"
                placeholder="Оберіть клієнта"
                label="name"
                track-by="name"
            />
          </div>

          <div style="cursor: pointer" @click="toggleAddClient" class="col-md-1 pe-0">
            <template v-if="addClient">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x"
                   width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" fill="none" stroke-linecap="round"
                   stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </template>
            <template v-else>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus"
                   width="22" height="22" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M16 19h6" />
                <path d="M19 16v6" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
              </svg>
              </template>
          </div>
        </div>
      </div>

      <div v-if="this.addClient" class="input-group mt-3">
        <ClientForm @submit-client="toggleAddClient"/>
      </div>

      <div class="input-group mt-3">
        <label class="form-check-label my-2">
          Менеджер
          <sup v-if="!this.orderId">
            <small>Буде додано автоматично</small>
          </sup>
        </label>
        <VueMultiselect
            v-model="this.managerData"
            :options="tempUsers"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            @select="mapChanges('managerId', $event.username)"
            @search-change="asyncFindUsers"
            placeholder="Оберіть менеджера"
            label="username"
            track-by="username"
        />
      </div>


      <FancyBoxGallery
          v-if="imageRecords.length > 0"
      >
        <div class="row my-3 align-items-center justify-content-between">
          <a v-for="(image) in imageRecords" :key="image.id"
             data-fancybox="gallery"
             class="col-md-3 my-2"
             :href="image.url">
            <img :src="image.url" :alt="image.name"
                 class="w-100 mw-100 object-fit-cover h-100"/>
          </a>
        </div>
      </FancyBoxGallery>


      <div class="input-group row my-3">
        <h4>Фото авто.</h4>
        <div class="col">
          <VueFileAgent
              ref="VueFileAgentNext"
              :auto="true"
              :uploadUrl="uploadUrl"
              :rawModelValue="imageRecords"
              :deletable="true"
              :multiple="true"
              :meta="true"
              :uploadWithCredentials="true"
              :helpText="'Оберіть файли'"
              :theme="'list'"
              :uploadHeaders="uploadHeaders"
              @upload="imageFilesUpload($event)"
              @beforedelete="this.onBeforeDelete($event)"
              @delete="customDelete($event)"

          ></VueFileAgent>
        </div>
      </div>

      <FilmCutsEditor v-if="this.orderId"
                      @cutsChange="mapChanges"
                      v-model="orderData.filmCuts"
      />

      <div v-if="this.orderId" class="input-group row mt-3">
        <h4>Файли для замовлення</h4>
        <div class="col">
          <VueFileAgent
              ref="fileAgent"
              :auto="true"
              :uploadUrl="uploadUrl"
              :rawModelValue="fileRecords"
              :deletable="true"
              :multiple="true"
              :meta="true"
              :linkable="true"
              :uploadWithCredentials="true"
              :helpText="'Оберіть файли'"
              :uploadHeaders="uploadHeaders"
              @upload="filesRecordsUpload($event)"
              @beforedelete="this.onBeforeDelete($event,'file')"
              @delete="customDelete($event)"

          ></VueFileAgent>
        </div>
      </div>
      <button type="button" class="btn btn-primary my-4 w-100" @click="submitOrder">
        <template v-if="this.orderId">
          Оновити
        </template>
        <template v-else>
          Додати
        </template>
      </button>
    </form>
  </div>


</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped lang="scss">
[data-fancybox="gallery"] {
  height: 150px;
}

small {
  font-size: 10px;
}
</style>