<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "StatusSelector",
  data() {
    return {
      statuses: [
        {
          name:'Нове замовлення',
          value: 'active'
        },
        {
          name:'Закрито',
          value: 'closed'
        },
        {
          name:'Завершено',
          value: 'done'
        },
        // {
        //   name:'В очікуванні',
        //   value: 'waiting'
        // },
        {
          name:'В роботі',
          value: 'work'
        },

      ],
      localModelValue: this.modelValue,
    }
  },
  emits: ['update:modelValue','mapChanges'],
  props: {
    modelValue: String,
  },
  watch: {
    modelValue(newVal) {
      this.localModelValue = newVal;
      this.$emit('mapChanges','status',this.mapStatusName);
    }
  },

  methods: {
    updateStatus() {
      this.$emit('update:modelValue', this.localModelValue);
    }
  },
  computed: {
    mapStatusName:function () {
      return this.statuses.find(item => item.value === this.localModelValue).name
    }
  }
})
</script>

<template>
  <div class="form-group mb-3">
    <label class="mb-2" for="statusSelector">Статус замовлення</label>

    <select class="form-select"  id="statusSelector" v-model="localModelValue" @change="updateStatus">
      <option v-for="status in statuses" :key="status.value" :value="status.value">{{ status.name }}</option>
    </select>
  </div>
</template>

<style scoped lang="scss">

</style>