<script setup>
import {useNotification} from "@kyvg/vue3-notification";
import store from "@/store";
import {onMounted, onUnmounted, getCurrentInstance,defineProps} from "vue";

const props = defineProps(['order'])

const notification = useNotification();
const instance = getCurrentInstance();

const socket = instance.appContext.config.globalProperties.$socket;

socket.on("new-info", (data) => {
  console.log(data, store.getters.userData.id)

  let messageText = ""
  if (data.order) {

    messageText += "<strong>Замовлення:</strong> " + data.order.title + "<br>";

    if (data.order?.draftsmanId?.username) {
      messageText += "<strong>Викройщик:</strong> " + data.order.draftsmanId.username + "<br>";
    }
    if (data.order?.clientId?.name) {
      messageText += "<strong>Клієнт:</strong> " + data.order.clientId.name + "<br>";
    }
    if (data.order?.managerId?.username) {
      messageText += "<strong>Менеджер:</strong> " + data.order.managerId.username + "<br>";
    }

  }

  if (data.context === "all") {
    showInfo(data?.title, messageText)
    return
  }

  if (data.context === "user" && data.users.includes(store.getters.userData.id)) {
    showWarning(data?.title, messageText)

  }
});

socket.on("wide-chat", (data) => {
  if (props.order === null || props?.order !== data.orderId._id) {
    if (data.text){
      showChatMess(data?.orderId.title, `<strong>${data?.user?.username}:</strong> ${data.text}`)
    }
    if (data.image){
      showChatMess(data?.orderId.title, `<strong>${data?.user?.username}:</strong> <br> <img class="mw-100 w-100" src="/${data.image.path}">`)
    }

  }

});

onMounted(() => {

})

onUnmounted(() => {
  // const socket = instance.appContext.config.globalProperties.$socket;
  // socket.off("new-log");

})

function showInfo(title = "", text = "") {
  const time = new Date();
  notification.notify({
    title: title ?? "Повідомлення",
    text: text.target ? text.target.innerText : text,
    duration: 10000,
    group: "info",
    data: {
      time: time.toLocaleString(),
    }
  })
  playSound("https://assets.mixkit.co/active_storage/sfx/2870/2870.wav")
}

function showChatMess(title = "", text = "") {
  const time = new Date();
  notification.notify({
    title: title ?? "Повідомлення",
    text: text.target ? text.target.innerText : text,
    duration: 120000,
    group: "chat",
    data: {
      time: time.toLocaleString(),
    }
  })
  playSound("https://assets.mixkit.co/active_storage/sfx/766/766-preview.mp3",0.55)
}

function showWarning(title = "", text = "") {
  const time = new Date();
  notification.notify({
    title: title ?? "Повідомлення",
    text: text,
    duration: 80000,
    group: "warning",
    data: {
      time: time.toLocaleString(),
    }
  })
  playSound("https://assets.mixkit.co/active_storage/sfx/2863/2863.wav")
}

function playSound(sound,volume = 0.33) {
  const audioContext = new AudioContext();
  audioContext.resume().then(() => {
    const audio = new Audio();
    audio.src = sound;
    audio.volume = volume;
    audio.play();
  });
}
</script>

<template>
  <div>

    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </symbol>
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
      </symbol>
      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </symbol>
      <symbol id="chat-fill"
              viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" fill="none"
              stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M8 9h8"/>
        <path d="M8 13h6"/>
        <path d="M15 18l-3 3l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5"/>
        <path d="M19 16v3"/>
        <path d="M19 22v.01"/>
      </symbol>
    </svg>


    <notifications max="5" group="info" position="top right">
      <template #body="props">
        <div class="alert my-notification  alert-success alert-dismissible fade show pe-0 py-2">
          <h5 class="title">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
              <use xlink:href="#info-fill"/>
            </svg>
            {{ props.item.title }}
          </h5>
          <button class="close btn-close btn" @click="props.close"></button>
          <p v-html="props.item.text"/>
          <small class="pt-1" v-html="props.item.data.time"></small>
        </div>
      </template>
    </notifications>

    <notifications max="5" group="warning" position="top left">
      <template #body="props">
        <div class="alert my-notification  alert-warning alert-dismissible fade show pe-0 py-2">
          <h5 class="title">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
              <use xlink:href="#exclamation-triangle-fill"/>
            </svg>
            {{ props.item.title }}
          </h5>
          <button class="close btn-close btn" @click="props.close"></button>
          <p v-html="props.item.text"/>
          <small class="pt-1" v-html="props.item.data.time"></small>
        </div>
      </template>
    </notifications>

    <notifications max="18" group="chat" position="bottom right">
      <template #body="props">
        <div class="alert my-notification  alert-dark alert-dismissible fade show pe-0 py-2">
          <h5 class="title">
            <svg width="24" height="24">
              <use xlink:href="#chat-fill"/>
            </svg>
            {{ props.item.title }}
          </h5>
          <button class="close btn-close btn" @click="props.close"></button>
          <p v-html="props.item.text"/>
          <small class="pt-1" v-html="props.item.data.time"></small>
        </div>
      </template>
    </notifications>
  </div>
</template>

<style lang="scss">
.my-notification {

  margin: 0 5px 5px;
  padding: 5px;
  font-size: 12px;
}
</style>

