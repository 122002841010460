<script>
import helpers from "../../.././helpers.js";
export default {
  name: "AllOrdersTable",
  props: {
    modelValue: Array,
  },
  emits: ['toOrder'],
  data() {
    return {
      activeClientId: this.modelValue || null,
      clientOrders: [],
    }
  },
  watch: {
    modelValue(newVal) {
      this.activeClientId = newVal;
      this.getOrders();
    }
  },
  methods: {
    getOrders() {
      if (this.activeClientId) {
        this.$http.get(`/api/clients/${this.activeClientId}/orders`).then((response) => {
          this.clientOrders = response.data;
        }).catch(e => {
          console.log(e)
        })
      }
    },
    convertDate(date) {
      return helpers.convertTime(date)
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
      <tr>
        <th scope="col">id</th>
        <th scope="col">Назва</th>
        <th scope="col">Менеджер</th>
        <th scope="col">Складальник</th>
        <th scope="col">Створено</th>
        <th scope="col">Статус</th>
      </tr>
      </thead>
      <tbody>
      <tr class="pointer" v-for="order in clientOrders" :key="order._id">
        <th scope="row">{{ order.humanId }}</th>
        <td>
          <router-link class="navbar-brand" target="_blank"  :to="'/order/' + order._id">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link"
                 width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
              <path d="M11 13l9 -9" />
              <path d="M15 4h5v5" />
            </svg>

          </router-link>

          {{ order.title }}
        </td>
        <td>{{ order?.managerId?.username }}</td>
        <td>{{ order?.draftsmanId?.username }}</td>
        <td>{{ convertDate(order?.createdAt) }}</td>
        <td>{{ order.status === 'done' ? 'Завершено' : 'Закрито' }} - {{ convertDate(order?.updatedAt) }}</td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>