<script>
import Swal from "sweetalert2";

export default {
  name: "ClientForm",
  emits: ['SubmitClient'],
  props: {
    modelValue: Array,
  },
  watch: {
    modelValue(newVal) {
      this.activeClient = newVal;
    }
  },
  data() {
    return {
      activeClient: this.modelValue || {},
    }
  },
  methods: {
    updateClient() {
      const client = this.activeClient;

      if (client?._id) {
        this.$http.put(`/api/clients/${client._id}`, client).then((response) => {
          this.$emit('SubmitClient');
          Swal.fire({
            title: 'Успішно',
            text: response.data?.message,
            icon: 'success',
          })
          this.activeClient = {};
        }).catch(e => {
          Swal.fire({
            title: 'Помилка',
            text: e.response.data?.error.message,
            icon: 'error',
          })

        })
      } else {
        this.$http.post('api/clients', client).then((response) => {
          this.$emit('SubmitClient');
          Swal.fire({
            title: 'Успішно',
            text: response.data?.message,
            icon: 'success',
          })

          this.activeClient = {};
        }).catch(e => {
          Swal.fire({
            title: 'Помилка',
            text: e.response.data?.error.message,
            icon: 'error',
          })
          console.log(e)
        })
      }
    }

  }
}
</script>

<template>
  <form @submit.prevent="updateClient" class="w-100" id="clientForm">
    <div class="input-group mb-3">
      <span class="input-group-text">Ім'я клієнта</span>
      <input type="text" class="form-control" required v-model="activeClient.name" placeholder="Ім'я клієнта">
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">Телефон клієнта</span>
      <input type="text" class="form-control" required v-model="activeClient.phone" placeholder="телефон">
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">Статус клієнта</span>
      <input type="text" class="form-control" v-model="activeClient.status" placeholder="статус">
    </div>

    <div class="input-group mt-3">
      <span class="input-group-text">Коротикий опис</span>
      <textarea v-model="activeClient.description" class="form-control" aria-label="With textarea"></textarea>
    </div>
    <button type="submit"  class="btn btn-primary mt-3 w-100">
      <template v-if="activeClient?._id">
        Підтвердити
      </template>
      <template v-else>
        Додати клієнта
      </template>

    </button>
  </form>
</template>