import {createStore} from 'vuex'
import axios from '../axios.config.js'
import Swal from 'sweetalert2'
import {eventBus} from '@/eventBus';
import texts from '/./texsts.js';


export default createStore({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        role:"",
        user: {}
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        userToken: state => state.token,
        isAdmin: state => state.role === 'admin',
        isManager: state => state.role === 'manager' || state.role === 'admin',
        isDraftsman: state => state.role === 'draftsman' || state.role === 'admin',
        userRole: state =>  state.role,
        userData: state => state.user
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, payload) {
            state.status = 'success'
            state.token = payload.token
            state.user = payload.user
            state.role = payload.user.role
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        setUserData(state, userData) {
            state.role = userData.user.role;
            state.user = userData.user;

        }
    },
    actions: {
        login({commit}, user) {

            return new Promise((resolve, reject) => {
                commit('auth_request')

                axios({url: '/api/login', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        localStorage.setItem('token', token)
                        eventBus.emit('update-token', token);
                        commit('auth_success', { token, user })
                        resolve(resp)
                    })
                    .catch(err => {
                        Swal.fire({
                            title: texts.titles.error,
                            text: err?.response?.data?.message,
                            icon: 'error',
                        });

                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        register({commit}, user) {

            return new Promise((resolve, reject) => {
                commit('auth_request')

                axios({url: '/api/register', data: user, method: 'POST'})
                    .then(resp => {

                        const token = resp.data.token
                        const user = resp.data.user
                        localStorage.setItem('token', token)
                        eventBus.emit('update-token', token);
                        commit('auth_success', token, user)
                        resolve(resp)
                    })
                    .catch(err => {
                        Swal.fire({
                            title: texts.titles.error,
                            text: err?.response?.data?.message,
                            icon: 'error',
                        });
                        commit('auth_error', err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                eventBus.emit('update-token', null);
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        fetchUserData({ commit }) {
            if (!this.getters.isLoggedIn) {
                return;
            }
            axios.get('/api/user', {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(response => {
                commit('setUserData', response.data);
            }).catch(error => {
                Swal.fire({
                    title: texts.titles.error,
                    text: error?.response?.data?.message,
                    icon: 'error',
                });
                console.error('Error fetching user data:', error);
            });
        }
    },
    modules: {}
})
