<script>
import {defineComponent} from 'vue'
import texts from "../../.././texsts.js";

export default defineComponent({
  name: "UserListItem",
  props: {
    user: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    deleteUser() {
      this.$swal.fire({
        title: texts.titles.alert,
        text: texts.asks.delete_user,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: texts.buttons.confirm,
        cancelButtonText: texts.buttons.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.delete("/api/users/" + this.user._id).then(() => {
            this.$swal.fire(texts.titles.success, texts.messages.user_deleted, "success");
          }).catch((e) => {
            this.$swal.fire(texts.titles.error, e.response?.data?.message, "error");
          })
        }

      })
    }
  }
})
</script>

<template>
  <li class="list-group-item d-flex justify-content-between align-items-center">
    <div class="ms-2 me-auto">
      <div data-bs-target="#editUserModal"
           data-bs-toggle="modal"
           :data-bs-user="user._id"
           class="fw-bold">
        {{ this.user.username }}
      </div>
      {{ this.user.role.name }}
    </div>
    <span class="badge bg-primary rounded-pill"> {{ this.user.ordersCount }}</span>
    <button class="btn btn-close position-absolute end-0 me-3" @click="deleteUser"></button>
  </li>
</template>

<style scoped lang="scss">
[data-bs-target="#editUserModal"] {
  cursor: pointer;
}
</style>