<script>
import RegisterPage from "@/components/RegisterPage.vue";

export default {
  name: "RegisterView",
  components: {RegisterPage}
}
</script>

<template>
  <div class="container"> <RegisterPage></RegisterPage> </div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
}
</style>