const texts = {
    errors:{
        user_not_found:"Користувача не знайдено",
        user_already_exists:"Користувач вже існує",
        wrong_password:"Невірний пароль",
        invalid_token:"Невірний токен",
        invalid_data:"Невірні дані",
        wrong_file_format:"Невірний формат файлу",
        access_denied:" Увас немає доступу до цієї функції",
        not_found:"Запис не знайдено",
        file_not_uploaded: "Файл не завантажено!",
        file_not_found:"Файл не знайдено!",
        order_delete_failed:"Не вдалося видалити замовлення!",

    },
    messages:{
        user_updated:"Користувач успішно оновлено",
        user_created:"Користувач успішно створено",
        user_deleted:"Користувач успішно видалено",
        client_updated:"Клієнта успішно оновлено",
        client_has_orders:"Клієнт має замовлення, видалення не можливе!",
        order_created:"Замовлення успішно створено",
        order_updated:"Замовлення успішно оновлено",
        file_deleted:"Файл {name} успішно видалено",
    },
    asks:{
        delete_user:"Ви впевнені, що хочете видалити користувача?",
        delete_file:"Ви впевнені, що хочете видалити цей файл?",
    },
    operations: {
        create: 'Створення',
        update: 'Оновлення',
        delete: 'Видалення',
        get: 'Отримання',
        getByType: 'Отримання за типом',
        getByDate: 'Отримання за датою',
        login: 'Авторизація (вхід)',
        logout: 'Вихід',
        upload: 'Завантаження файлу',
        get_order: 'Отримання даних замовлення',
        edit_order: 'Редагування даних замовлення',
        error: 'Помилка в системі',

    },
    titles:{
        error: 'Помилка',
        success: 'Успішно',
        alert: 'Увага',
    },
    buttons:{
        create:"Створити",
        update:"Оновити",
        delete:"Видалити",
        confirm:"Підтвердити",
        cancel:"Скасувати",
        get:"Отримати",
        upload:"Завантажити",
        get_order:"Отримати замовлення",
        edit_order:"Редагувати замовлення",

    }

}

module.exports = texts;