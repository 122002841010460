<script>
import OrdersPage from "@/components/OrdersPage.vue";

export default {
  name: "OrdersView",
  components: {
    OrdersPage,
  }
}
</script>

<template>
  <div class="container">
    <OrdersPage/>
  </div>
</template>

<style scoped lang="scss">

</style>