<script>
export default {
  name: "UserInfoBar",

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    userInfo() {
      return this.$store.getters.userData;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    }
  }
}
</script>

<template>
  <div class="col align-items-center justify-content-end text-end" v-if="isLoggedIn">
    <span v-if="userInfo" id="userInfo" class="me-2 ">
      <strong>
        {{ userInfo.login }}
      </strong>
      <small>{{ userInfo.role}}</small>
    </span>

    <button v-if="isLoggedIn"
            @click="logout"
            class="btn btn-outline-warning ms-2"
            type="button"
    >
      Вийти
    </button>
  </div>
</template>

<style scoped lang="scss">

#userInfo{
  position: relative;
  text-transform: capitalize;
  small{
    position: absolute;
    left: 0;
    bottom: -15px;
    text-transform: lowercase;
  }
}
small{
  font-weight: lighter;
}
</style>
