<script setup>

import {ref, onMounted, defineEmits} from 'vue';
import ImageUpload from "@/components/ImageUpload.vue";

const emit = defineEmits(['getCutData'])

const cutData = ref({
  index: null,
  width: null,
  height: 0,
  imageId: 1,
  path: null
});
const closeButton = ref(null);
const filmCutsWidth = [
  '1.83',
  '1.52',
  '1.22',
  '0.91',
  '0.76',
  '0.61',
  '0.3'
]
onMounted(() => {
  const editCutModal = document.getElementById('editCutModal');

  editCutModal.addEventListener('show.bs.modal', function (event) {
    const button = event.relatedTarget,
        cutIndex = button.getAttribute('data-index') ?? null,
        imageId = button.getAttribute('data-image-id') ?? null;

    cutData.value = {
      index: cutIndex,
      width: parseFloat(button.getAttribute('data-width') ?? 0),
      height: parseFloat(button.getAttribute('data-height') ?? 0),
      note: button.getAttribute('data-note'),
      imageId: imageId,
      path: button.getAttribute('data-path') ?? ""
    };
  })

  editCutModal.addEventListener('hidden.bs.modal', function () {
    if(cutData.value.index){
      emit('getCutData', cutData.value);
    }
  })

})


function getCutData() {
  emit('getCutData', cutData.value);
  closeModal()
  return cutData.value;
}

function closeModal() {
  if (closeButton.value) {
    closeButton.value.click();
  }
}

const formatFilmCutsHeight = function (input) {
  let formattedInput = input.replace(/,/g, '.').replace(/[^0-9.]/g, '');

  const firstDotIndex = formattedInput.indexOf('.');
  if (firstDotIndex !== -1) {
    formattedInput = formattedInput.substring(0, firstDotIndex + 1) + formattedInput.substring(firstDotIndex + 1).replace(/\./g, '');
  }

  this.cutData.height = formattedInput;
}


function getImageData(data){
  cutData.value.imageId = data._id
  if (data.path) {
    cutData.value.path =  "/" + data.path;
  } else{
    cutData.value.path = ""
  }
}
</script>

<template>

  <div class="col-md-4 align-items-center justify-content-center d-flex ">
    <div id="addFilmCutsItem" data-bs-toggle="modal" data-bs-target="#editCutModal"
         class="w-100 d-flex justify-content-center align-items-center">
      <span>+</span>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="editCutModal" tabindex="-1"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         aria-labelledby="editCutModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editCutModalLabel">
              <template v-if="cutData.index">
                Редагувати кусок {{ Number(cutData.index) + 1 }}
              </template>
              <template v-else>
                Додати кусок плівки до замовлення
              </template>
            </h5>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    ref="closeButton"
                    aria-label="Close">

            </button>
          </div>
          <div class="modal-body">
            <div class="input-group mb-3">
              <small class="d-block w-100 mb-2">Ширина плівки</small>
              <select class="form-select" v-model="cutData.width">
                <option v-for="(element,index) in filmCutsWidth" :key="index" :value="element">{{ element }}</option>
              </select>
            </div>

            <div class="input-group mb-3">
              <span class="input-group-text">Довжина плівки</span>
              <input type="text"
                     autocomplete="off"
                     class="form-control"
                     @input="formatFilmCutsHeight($event.target.value)"
                     v-model="cutData.height"
              >
            </div>


            <div class="input-group">
              <span class="input-group-text">Примітка</span>
              <textarea class="form-control" v-model="cutData.note"></textarea>
            </div>
            <div class="input-group mt-3">
              <ImageUpload :maxSize="5"
                          :key="cutData.imageId"
                          :image-id="cutData.imageId"
                          @file-uploaded="getImageData"
                          accept="jpg,png,webp,gif,jpeg" />

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="getCutData" class="btn btn-primary w-100">
              <template v-if="cutData.index">
                Застосувати
              </template>
              <template v-else>
                Додати
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss">
#addFilmCutsItem {
  background: var(--bs-tertiary-color);
  height: 130px;
  text-align: center;
  color: white;
  cursor: pointer;
  opacity: .55;

  span {
    font-size: 50px;
  }
}
</style>