<script>
export default {
  name: "RegisterPage",
  data(){
    return {
      username : "",
      password : "",
      password_confirmation : "",
      role:"user",
      is_admin : null
    }
  },
  methods: {
    register: function () {
      let data = {
        username: this.username,
        password: this.password,
        role: this.role,
        is_admin: this.is_admin
      }
      this.$store.dispatch('register', data)
          .then(() => this.$router.push('/'))
          .catch(err => console.log(err))
    }
  }
}
</script>

<template>

  <div class="row align-items-center justify-content-center h-50">
    <h1 class="col-12">Реєстрація в системі</h1>
    <form @submit.prevent="register" class="col-md-4 ">
      <label for="username" class="form-label">Ваш логін</label>
      <div class="input-group mb-3">
        <span class="input-group-text" id="username">@</span>
        <input type="text"
               v-model="username"
               class="form-control"
               placeholder="Придумайте логін"
               aria-label="Username"
               aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Ваш пароль</span>
        <input type="password"
               v-model="password"
               class="form-control"
               placeholder="Придумайте пароль"
               aria-label="Username"
               aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Підтвердіть пароль</span>
        <input type="password"
               v-model="password_confirmation"
               class="form-control"
               placeholder="Придумайте пароль"
               aria-label="Username"
               aria-describedby="basic-addon1">
      </div>

      <button type="submit" class="btn btn-success w-50 mx-auto">Зареєструватись</button>

    </form>
  </div>

</template>

<style scoped lang="scss">

</style>