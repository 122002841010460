<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue';
import { Popover } from 'bootstrap';

// Використання v-model
const props = defineProps({
  modelValue: String
});

 defineEmits(['update:modelValue']);


const popOverItem = ref(null);
let bootstrapPopover = null;

onMounted(() => {
  bootstrapPopover = new Popover(popOverItem.value, {
    content: props.modelValue
  });
});

watch(() => props.modelValue, (newValue) => {
  if (bootstrapPopover) {
    bootstrapPopover.dispose();
    bootstrapPopover = new Popover(popOverItem.value, { content: newValue });
  }
});
</script>

<template>
<span
    ref="popOverItem"
    class="cutNote"
    data-bs-container="body"
    data-bs-toggle="popover"
    data-bs-placement="left"
    >
             <svg xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-clipboard-text"
                  width="22" height="22"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#6f32be"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/>
                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"/>
                <path d="M9 12h6"/>
                <path d="M9 16h6"/>
              </svg>
            </span>
</template>

<style scoped lang="scss">
.cutNote {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
</style>