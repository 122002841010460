<template>
  <div :class="messageClasses">
    <template v-if="msgUser._id === userData.id">
      <div ref="massage" class="pe-2 msg-wrapper">
      <span class="name">
       {{ msgUser.username }}
        <strong>
          {{ parseTime(time) }}
        </strong>
      </span>
        <div v-if="mediaType" class="media-message">

          <p v-if="mediaType==='image'" class="msg d-flex flex-column justify-content-center align-items-start">
            <img
                :data-fancybox="'chatGallery-'+ msgUser._id"
                class="mb-2 w-100 mw-100"
                :src="'/' + image?.path"
                :alt="image?._id">

            {{ msg }}
          </p>

          <p v-if="mediaType==='video'" class="msg d-flex flex-column justify-content-center align-items-start p-2">
            <video class="mb-2"  :src="'/' + image?.path" controls height="150">
            </video>
            {{ msg }}
          </p>

        </div>

        <p v-else class="msg d-flex flex-column justify-content-center align-items-start">
          {{ msg }}
        </p>

      </div>
      <div class="ms-1 user-avatar">{{ userNameLetters }}</div>
    </template>
    <template v-else>
      <div class="user-avatar me-1">{{ userNameLetters }}</div>
      <div class="msg-wrapper  ps-2">
      <span class="name">
       {{ msgUser.username }}
        <strong>
          {{ parseTime(time) }}
        </strong>
      </span>
        <div v-if="mediaType" class="media-message">

          <p v-if="mediaType==='image'" class="msg d-flex flex-column justify-content-center align-items-start">
            <img
                :data-fancybox="'chatGallery-'+ msgUser._id"
                class="mb-2 w-100 mw-100"
                :src="'/' + image?.path"
                :alt="image?._id">

            {{ msg }}
          </p>

          <p v-if="mediaType==='video'" class="msg d-flex flex-column justify-content-center align-items-start p-2">
            <video class="mb-2"  :src="'/' + image?.path" controls height="150">
            </video>
            {{ msg }}
          </p>


        </div>

        <p v-else class="msg d-flex flex-column justify-content-center align-items-start">
          {{ msg }}
        </p>
      </div>

    </template>
  </div>
</template>

<script>
import helpers from "../../.././helpers.js"
import {Fancybox} from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
  name: "ChatMessage",
  data() {
    return {
      options: {
        groupAll: true,
        infinite: true
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
    messageClasses() {
      return {
        'd-flex align-items-center': true,
        'text-right justify-content-end': this.msgUser._id === this.userData.id
      };
    },
    userNameLetters() {
      return this.msgUser.username[0].toUpperCase() + this.msgUser.username[1];
    },
    mediaType() {
      if (this.image?.type === 'video/mp4') {
        return 'video'
      }
      if (this.image?.type === 'image/jpeg' || this.image?.type === 'image/png') {
        return 'image'
      }
      return null
    },
  },
  methods: {
    parseTime(time) {
      return helpers.convertTime(time)
    }
  },
  mounted() {
    Fancybox.bind(this.$refs.massage, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  updated() {
    Fancybox.unbind(this.$refs.massage);
    Fancybox.close();

    Fancybox.bind(this.$refs.massage, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  unmounted() {
    Fancybox.destroy();
  },
  props: {
    id: String,
    msgUser: Object,
    msg: String,
    time: Object,
    image: Object
  }
}
</script>
<style scoped lang="scss">
.msg-wrapper {
  max-width: 85%;
  min-width: 30%;
}

.user-avatar {
  width: 30px;
  height: 30px;
  padding: 5px;
  background: var(--bs-dark);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.name {
  font-size: 8px
}

.msg {
  background-color: #fff;
  font-size: 11px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  color: #3e3c3c;
  word-break: break-word;

  img {
    height: 200px;
    object-fit: cover;
    background: rgba(62, 60, 60, 0.59);
    color: white;
  }

}
</style>

