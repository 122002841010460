<script>
import {defineComponent} from 'vue'
import OrderPage from "@/components/OrderPage.vue";

export default defineComponent({
  name: "OrderView",
  components: {OrderPage},
})
</script>

<template>
<div class="container">
<OrderPage />
</div>
</template>

<style scoped lang="scss">

</style>