<script>
import {defineComponent} from 'vue'
import texts from "../../.././texsts.js";

export default defineComponent({
  name: "ClientListItem",
  props: {
    client: Object,
    default: () => ({})

  },
  methods: {
    deleteClient() {
      if (this.client?.orderCount > 0) {
        this.$swal.fire({
          title: texts.titles.error,
          icon: 'error',
          text: texts.messages.client_has_orders,
        })
        return

      }
      this.$swal.fire({
        title: texts.titles.alert,
        icon: 'warning',
        text: `Ви дійсно бажаєте видалити клієнта ${this.client?.name}?`,
        showCancelButton: true,
        confirmButtonText: texts.buttons.confirm,
        cancelButtonText: texts.buttons.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.delete("/api/clients/" + this.client?._id).then(() => {
            this.$swal.fire(texts.titles.success, "", "success");
          }).catch((e) => {
            this.$swal.fire(texts.titles.error, e.response?.data?.message, "error");
          })
        }

      })

    }
  }
})
</script>

<template>
  <li class="list-group-item d-flex justify-content-between align-items-center">
    <div class="ms-4 me-auto">
      <span :data-bs-client="client?._id"
            data-bs-target="#editClientModal"
            data-bs-toggle="modal"
            class="fw-bold">
        {{ this.client?.name }}
      </span>
      <br>
      телефон: {{ this.client?.phone }}
    </div>

    <div class="client_btns ms-auto ps-3">
      <button
          :data-bs-client="client?._id"
          data-bs-target="#clientOrdersModal"
          data-bs-toggle="modal"
          class="btn btn-outline-warning">
        Замовлення - <strong>{{ this.client?.orderCount }}</strong>
      </button>
      <button class="btn btn-outline-danger  ms-2 me-2" @click="deleteClient">
        Видалити клієнта
      </button>
    </div>


  </li>
</template>

<style scoped lang="scss">
[data-bs-target="#editClientModal"] {
  cursor: pointer;
}

strong {
  color: var(--bs-dark);
}
</style>