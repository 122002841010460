import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {eventBus} from './eventBus';
import io from 'socket.io-client'
import axios from './axios.config.js'


import VueSweetalert2 from 'vue-sweetalert2'
import VueFileAgentNext from '@boindil/vue-file-agent-next'
import Notifications from '@kyvg/vue3-notification'
import 'bootstrap/scss/bootstrap.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css'

const app = createApp(App)

// Функція для ініціалізації WebSocket
function initializeSocket(token) {
    app.config.globalProperties.$socket = io("/", {auth: {token}});
}

app.config.globalProperties.$http = axios

// Ініціалізація з початковим токеном
let initialToken = localStorage.getItem('token')
app.config.globalProperties.$http.defaults.headers.common['Authorization'] = initialToken;
initializeSocket(initialToken);


eventBus.on('update-token', (newToken) => {
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = newToken;
    initializeSocket(newToken);
});

app.use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(VueFileAgentNext)
    .use(Notifications)
    .mount('#app')

import 'bootstrap/dist/js/bootstrap.js'
import './registerServiceWorker'