<script>
import LoginPage from "@/components/LoginPage.vue";

export default {
  name: "LoginView",
  components: {LoginPage}
}
</script>

<template>
  <div>
    <LoginPage></LoginPage>
  </div>

</template>

<style scoped lang="scss">

</style>