<script setup>
import {getCurrentInstance, onMounted, onUnmounted, ref} from 'vue';
import Swal from 'sweetalert2';

import $http from "@/axios.config";
import store from "@/store";
import LogsTable from "@/components/admin-page/LogsTable.vue";
import ClientListItem from "@/components/items/ClientListItem.vue";
import UserListItem from "@/components/items/UserListItem.vue";
import ClientForm from "@/components/forms/ClientForm.vue";
import {VueAwesomePaginate} from "vue-awesome-paginate";

import "vue-awesome-paginate/dist/style.css";
import AllOrdersTable from "@/components/parts/AllOrdersTable.vue";

const users = ref([]),
    clients = ref([]),
    roles = ref([]),
    logs = ref([]),
    activeUser = ref({role: ""}),
    activeClient = ref({}),
    activeClientId  = ref(null);

const closeButton = ref(null);
const instance = getCurrentInstance();

let clientsPagination = {
      page: 1,
      perPage: 20,
      totalItems: 0,
      name: "",
    },
    usersPagination = {
      page: 1,
      perPage: 20,
      totalItems: 0,
      name: "",
    },
    logsPagination = {
      page: 1,
      perPage: 20,
      totalItems: 0,
      name: "",
    };

function closeModal() {
  if (closeButton.value) {
    closeButton.value.click();
  }
}

onMounted(() => {
  const socket = instance.appContext.config.globalProperties.$socket;

  const editUserModal = document.getElementById('editUserModal'),
      editClientModal = document.getElementById('editClientModal'),
      clientOrdersModal = document.getElementById('clientOrdersModal');

  editUserModal.addEventListener('show.bs.modal', function (event) {

    const button = event.relatedTarget;
    const recipient = button.getAttribute('data-bs-user');
    if (recipient) {
      editUser(recipient)
    } else {
      editUser(null)
    }

  })
  editClientModal.addEventListener('show.bs.modal', function (event) {

    const button = event.relatedTarget;
    const recipient = button.getAttribute('data-bs-client');
    if (recipient) {
      editClient(recipient)
    } else {
      editClient(null)
    }

  })

  clientOrdersModal.addEventListener('show.bs.modal', function (event) {
    const button = event.relatedTarget;
    activeClientId.value = button.getAttribute('data-bs-client');
    activeClient.value = clients.value.find(client => client._id === activeClientId.value);

  })

  $http.get('api/users/roles').then((response) => {
    roles.value = response.data;
  }).catch(e => {
    console.log(e)
  })

  socket.on("new-log", () => {
    getLogs();
  });
  getLogs();

  socket.on("update-client", () => {
    showClients();
  });
  socket.on("update-users", () => {
    showUsers();
  });
})
onUnmounted(() => {
  const socket = instance.appContext.config.globalProperties.$socket;
  socket.off("new-log");
});

const getLogs = function () {

  if (store.getters.userData.role !== "admin") {
    return;
  }

  $http.get('api/logs',{
    params: {
      limit: logsPagination.perPage,
      page: logsPagination.page
    }
  }).then((response) => {
    logs.value = response.data.data;
    logsPagination.totalItems = response.data.total
  }).catch(e => {
    console.log(e)

  })
}
const showUsers = function () {
  $http.get('api/users',{
    params: {
      limit: usersPagination.perPage,
      page: usersPagination.page,
      name: usersPagination.name
    }
  }).then((response) => {
    users.value = response.data.data;
    usersPagination.totalItems = response.data.total;
  }).catch(e => {
    console.log(e)
  })
}

const showClients = function () {
  $http.get('api/clients', {
    params: {
      limit: clientsPagination.perPage,
      page: clientsPagination.page,
     name: clientsPagination.name

    }
  }).then((response) => {
    clients.value = response.data.clientData;
    clientsPagination.totalItems = response.data.clientsCount
  }).catch(e => {
    console.log(e)
  })
}


function editUser(userId) {
  const user = users.value.find(user => user._id === userId);
  if (user) {
    activeUser.value = {
      id: user?._id,
      username: user?.username,
      role: user?.role._id,
      password: '',
      isActive: user?.isActive
    };
  } else {
    activeUser.value = {role: ""};
  }
}

function editClient(clientId) {
  const client = clients.value.find(client => client._id === clientId);
  if (client) {
    activeClient.value = client;
  } else {
    activeClient.value = {};
  }
}

function updateUser() {
  const user = activeUser.value;
  if (user.id) {
    $http.put(`/api/users/${user.id}`, user).then((response) => {
      showUsers()
      closeModal();
      Swal.fire({
        title: 'Успішно',
        text: response.data?.message,
        icon: 'success',
      })
    }).catch(e => {
      Swal.fire({
        title: 'Помилка',
        text: e.response.data?.error.message,
        icon: 'error',
      })

    })
  } else {
    $http.post('api/users', user).then((response) => {
      showUsers()
      Swal.fire({
        title: 'Успішно',
        text: response.data?.message,
        icon: 'success',
      })
    }).catch(e => {
      Swal.fire({
        title: 'Помилка',
        text: e.response.data?.error.message,
        icon: 'error',
      })
      console.log(e)
    })
  }
}

</script>

<template>
  <div class="container mt-5">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
          v-if="store.getters.isAdmin"
          class="nav-item"
          role="presentation">
        <button class="nav-link active"
                id="history-tab"
                data-bs-toggle="tab"
                data-bs-target="#history"
                type="button"
                role="tab"
                aria-controls="history"
                aria-selected="true"
                @click="getLogs"
        >
          Історія
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link"
                id="clients-tab"
                data-bs-toggle="tab"
                data-bs-target="#clients" type="button" role="tab"
                aria-controls="clients"
                aria-selected="false"
                @click="showClients"
        >
          Клієнти
        </button>
      </li>
      <li
          v-if="store.getters.isAdmin"
          class="nav-item" role="presentation">
        <button class="nav-link"
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                @click="showUsers"
        >
          Користувачі
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">

      <div v-if="store.getters.isAdmin"
           class="tab-pane fade show active"
           id="history"
           role="tabpanel"
           aria-labelledby="history-tab">

        <LogsTable :logs="logs"/>

        <div class="paginate mt-3 d-flex justify-content-center">
          <vue-awesome-paginate
              :total-items="logsPagination.totalItems"
              :items-per-page="logsPagination.perPage"
              :max-pages-shown="15"
              v-model="logsPagination.page"
              :on-click="getLogs"
          />
        </div>

      </div>

      <div
          v-if="store.getters.isAdmin"
          class="tab-pane fade show "
          id="users"
          role="tabpanel"
          aria-labelledby="users-tab">

        <div class="input-group mt-3">
          <input type="text" class="form-control"
                 v-model="usersPagination.name"
                 @input="showUsers"
                 placeholder="Пошук користувача за іменем">
        </div>

        <ol class="list-group list-group-numbered my-4">
          <UserListItem v-for="(user,index) in users" :key="index" :user="user"/>
        </ol>

        <button class="btn btn-primary w-100" data-bs-target="#editUserModal" data-bs-toggle="modal">
          + Додати користувача
        </button>

        <div class="paginate mt-3 d-flex justify-content-center">
          <vue-awesome-paginate
              :total-items="usersPagination.totalItems"
              :items-per-page="usersPagination.perPage"
              :max-pages-shown="5"
              v-model="usersPagination.page"
              :on-click="showUsers"
          />
        </div>

      </div>

      <div class="tab-pane fade"
           id="clients"
           role="tabpanel"
           aria-labelledby="clients-tab">

        <div class="input-group mt-3">
          <input type="text" class="form-control"
                 v-model="clientsPagination.name"
                 @input="showClients"
                 placeholder="Пошук клієнта за іменем">
        </div>


        <ol class="list-group list-group-numbered my-4">
          <ClientListItem v-for="(client,index) in clients" :key="index" :client="client"/>
        </ol>

        <button class="btn btn-primary w-100 my-2" data-bs-target="#editClientModal" data-bs-toggle="modal">
          + Додати клієнта
        </button>

        <div class="paginate mt-3 d-flex justify-content-center">
          <vue-awesome-paginate
              :total-items="clientsPagination.totalItems"
              :items-per-page="clientsPagination.perPage"
              :max-pages-shown="5"
              v-model="clientsPagination.page"
              :on-click="showClients"
          />
        </div>

      </div>
    </div>


    <div class="modal fade" id="editUserModal"
         data-bs-backdrop="true"
         data-bs-keyboard="false"
         tabindex="-1" aria-labelledby="editUserModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editUserModalLabel">
              <template v-if="activeUser.username">
                Редагування {{ activeUser.username }}
              </template>
              <template v-else>
                Додати користувача
              </template>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeButton"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Логін користувача" v-model="activeUser.username">
            </div>
            <div class="form-group mt-3">
              <input type="password" class="form-control" placeholder="Пароль користувача"
                     v-model="activeUser.password">
            </div>
            <div class="form-group mt-3">
              <label class="pb-1" for="userGroup">Група користувача</label>
              <select class="form-control" v-model="activeUser.role" id="userGroup">
                <option v-for="role in roles" :key="role._id" :value="role._id">{{ role.name }}</option>
              </select>
            </div>

            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" v-model="activeUser.isActive" id="isActiveUser">
              <label class="form-check-label" for="isActiveUser">
                Користувач активний
              </label>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" @click="updateUser" class="btn btn-primary">Підтвердити</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editClientModal"
         data-bs-backdrop="true"
         data-bs-keyboard="false"
         tabindex="-1" aria-labelledby="editClientModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editClientModalLabel">
              <template v-if="activeClient.name">
                Редагування {{ activeClient.name }}
              </template>
              <template v-else>
                Додати клієнта
              </template>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeButton"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <ClientForm v-model="activeClient" @submit-client="closeModal"/>

          </div>
          <div class="modal-footer">

          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="clientOrdersModal"
         data-bs-backdrop="true"
         data-bs-keyboard="false"
         tabindex="-1" aria-labelledby="clientOrdersModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="clientOrdersModalLabel">
              <template v-if="activeClient.name">
                Замовлення клієнта {{ activeClient.name }}
              </template>
            </h5>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    ref="closeButton"
                    aria-label="Close">

            </button>
          </div>
          <div class="modal-body">
            <AllOrdersTable v-model="activeClientId"/>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<style lang="scss">
.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: var(--bs-gray-300);
  border: 1px solid var(--bs-gray-300);
  color: black;

  &:hover {
    opacity: .80;
  }
}

.active-page {
  background-color: var(--bs-primary);
  border: 1px solid;
  color: white;

  &:hover {
    opacity: .80;
    background-color: var(--bs-primary);
  }
}

</style>