<script>
export default {
  name: 'AppLogo',
  props: {
    width: Number
  }
}
</script>

<template>
<div>
  <img :width="width" class="mw-100" alt="App logo" src="../assets/logo.png">
</div>
</template>

<style scoped lang="scss">

</style>