<script>
export default {
  name: "LogsTable",
  data: () => ({
    getClassForLogType:function (type){
      switch (type) {
        case 'create': return 'table-success';
        case 'update': return 'table-warning';
        case 'delete': return 'table-dark';
        case 'edit_order': return 'table-primary';
        case 'upload': return 'table-info';
        case 'error': return 'table-danger';
        default:
          return '';

      }
    }
  }),
  props: {
    logs: {
      type: Array
    }
  },
  methods: {
    parseDescription: function (desc) {
      if (!desc) {
        return '';
      }
      try {
        const data = JSON.parse(desc);
        if (typeof data === 'object'){
          const keys = Object.keys(data);
          const values = Object.values(data);
          const result = [];
          for (let i = 0; i < keys.length; i++) {
            result.push(`<div class=""><strong>${keys[i]}:</strong> ${values[i]}</div>`)
          }
          return result.join('');
        }
      }
      catch (e) {
        return desc
      }
    }
  }
}
</script>

<template>
  <table class="table mt-4">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Тип</th>
      <th scope="col">Користувач</th>
      <th scope="col">Примітка</th>
      <th scope="col">Датa</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(log, index) in logs" :key="log._id" :class="getClassForLogType(log.type)">
      <th scope="row">{{ index + 1 }}</th>
      <td style="max-width: 200px" >{{ log.typeTranslate }}</td>
      <td>{{ log.systemUser?.username }}</td>
      <td>
        <div class="table-cell-collapse" v-if="log.description">
        <button class="btn btn-info" type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#log' + log._id"
                aria-expanded="false"
                :aria-controls="'#log' + log._id">
          Показати
        </button>
        <div class="collapse" :id="'log' + log._id">
          <div v-html="parseDescription(log.description)"></div>
        </div>
        </div>
      </td>
      <td>{{ log.updatedAt }}</td>
    </tr>
    </tbody>
  </table>
</template>


<style scoped lang="scss">
.table-cell-collapse {
  max-width: 200px; /* або інша бажана ширина */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

</style>