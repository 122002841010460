<script>
import {defineComponent} from 'vue'
import OrderForm from "@/components/forms/OrderForm.vue";
import $http from "@/axios.config";
import Swal from "sweetalert2";
import texts from "../../texsts";
import LogsTable from "@/components/admin-page/LogsTable.vue";
import ChatPage from "@/components/order-chat/ChatPage.vue";

export default defineComponent({
  name: "OrderPage",
  components: {LogsTable, OrderForm, ChatPage},
  data() {
    return {
      orderData: {},
      logs: []
    }
  },
  methods: {
    sendOrder(data) {
      $http.put('/api/orders/' + data._id, data).then(() => {
        Swal.fire({
          icon: 'success',
          title: texts.titles.success,
          text: texts.messages.order_updated,
        });
        this.$router.push('/')
      })
    },

  },
  mounted() {

    this.$http.get("/api/logs/order/" + this.$route.params.id).then((res) => {
      this.logs = res.data
    })
  }
})
</script>

<template>
  <div class="row mt-4">
    <div class="col-md-6">

      <OrderForm :order-id="this.$route.params.id" @SubmitOrder="sendOrder"/>
    </div>
    <div class="col-md-6">
      <ChatPage/>
    </div>
    <div class="col-12">
      <h3>Історія змін</h3>
      <LogsTable v-if="logs" :logs="logs"/>
    </div>

  </div>
</template>

<style lang="scss">
.fancybox__container {
  z-index: 1500 !important;

}
</style>