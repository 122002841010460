<script>
import {defineComponent} from 'vue'
import helpers from "../../.././helpers.js";
import UserBadge from "@/components/parts/UserBadge.vue";
import texts from "../../../texsts";

export default defineComponent({
  name: "OrderInWork",
  components: {
    UserBadge
  },
  data() {
    return {
      userData: {}
    }
  },
  props: {
    order: Object,
    default: () => ({})

  },
  mounted() {
    this.userData = this.$store.getters.userData
  },
  methods: {
    orderToWork() {
      this.$swal.fire({
        title: this.userData?.login + " Ви дійсно бажаєте взяти замовлення в роботу?",
        showCancelButton: true,
        confirmButtonText: texts.buttons.confirm,
        cancelButtonText: texts.buttons.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.post("/api/orders/to-work/" + this.order._id, {
            draftsmanId: this.$store.getters.userData.id
          }).then(() => {
            this.$swal.fire(texts.titles.success, "", "success");
          }).catch((e) => {
            console.error(e)
            this.$swal.fire(texts.titles.error, e.response?.data?.message, "error");
          });
        }
      });
    },
    orderToArchive() {
      this.$swal.fire({
        title: texts.titles.alert,
        text: `${this.userData?.login} ви дійсно бажаєте видалити замовлення ${this.order.title} ? Це видалить всі пов'язані з ним дані`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: texts.buttons.confirm,
        cancelButtonText: texts.buttons.cancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.delete("/api/orders/" + this.order._id).then(() => {
            this.$swal.fire(texts.titles.success, "", "success");
          }).catch((e) => {
            this.$swal.fire(texts.titles.error, e.response?.data?.message, "error");
          });
        }
      });
    },
    timeSince(date) {
      return helpers.timeSince(date)
    },
    idSlice(id) {
      return helpers.getLastCharacters(id)
    }
  },
  computed: {
    firstImage() {
      if (this.order?.imagesId[0]) {
        return this.order?.imagesId[0].path
      }
      return '/uploads/websiteplanet-dummy-540X400.png'
    },
    getAlertClass() {
      return {
        'cursor_pointer':true,
        'alert-warning': (this.order.status ==="active")
            && (helpers.getMinutesSince(this.order.updatedAt) > 5)
            && (helpers.getMinutesSince(this.order.updatedAt) < 15),
        'alert-danger': (this.order.status ==="active")
            &&  helpers.getMinutesSince(this.order.updatedAt) >= 15
      }
    }
  }
})
</script>

<template>
  <div :class="getAlertClass">
    <div class="card mb-3 mw-100 mx-auto">
       <span v-if="this.order.isUrgent"
             class="
             z-3
         position-absolute
         top-0
         start-0
         translate-middle
         badge
         rounded-pill bg-danger"
       >
    Терміново!
  </span>
      <img @click="this.$router.push(`/order/${this.order._id}`)" :src="'/' + firstImage" class="card-img-top" alt="">
      <button type="button"
              v-if="this.$store.getters.isManager"
              class="btn btn-close position-absolute end-0 p-3 z-2 "
              @click="orderToArchive"
              title="Видалити замовлення"
      ></button>
      <div @click="this.$router.push(`/order/${this.order._id}`)"
           class="card-body">
        <h6 class="card-title">
          ID: <strong>{{ this.order?.humanId }}</strong>
        </h6>
        <h5 class="card-title">
          {{ this.order.title }}
        </h5>
        <p class="card-text">
          {{ this.order.description }}
        </p>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span>
            <strong>Клієнт: </strong>
            {{ this.order?.clientId?.name }}
          </span>
        </li>
        <li class="list-group-item">
          <span>
            <strong>Менеджер: </strong>
            <UserBadge
                :userId="this.order?.managerId?._id"
                :userName="this.order?.managerId?.username"
            />
          </span>
        </li>
        <li v-if="this.order?.status !== 'active'" class="list-group-item">
          <small>
            <strong>Складальник: </strong>
            <UserBadge
                :userId="this.order?.draftsmanId?._id"
                :userName="this.order?.draftsmanId?.username"
            />
          </small>
        </li>
        <li v-else class="list-group-item">
          <button v-if="this.$store.getters.isDraftsman"
                  type="button"
                  class="btn-warning btn mx-1 mx-md-2 w-100"
                  @click="orderToWork"
          >В роботу
          </button>
        </li>

      </ul>

      <div class="card-body d-flex flex-column align-items-start justify-content-center">
        <span class="badge bg-secondary">
          <strong>Створено: </strong>
          {{ this.timeSince(this.order.createdAt) }}
        </span>
        <span class="mt-2 badge bg-primary">
          <strong>Останнє оновлення: </strong>
          {{ this.timeSince(this.order.updatedAt) }}
        </span>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.card-img-top {
  height: 20vh;
  object-fit: cover;
  background: var(--bs-gray-600);
}
.cursor_pointer {
  cursor: pointer;
}
.alert-warning{
  .card {
    animation: yellow_blink-animation 1s cubic-bezier(.68,-0.55,.27,1.55) infinite;
  }
}
.alert-danger {
  .card {
    animation: red_blink-animation 1s cubic-bezier(.68,-0.55,.27,1.55) infinite;
  }
}

@keyframes red_blink-animation {
  0% {
    box-shadow: 0 0 0 var(--bs-danger);
  }

  100% {
    box-shadow: 0 0 10px var(--bs-danger);
  }
}
@keyframes yellow_blink-animation {
  0% {
    box-shadow: 0 0 0 var(--bs-warning);
  }

  100% {
    box-shadow: 0 0 10px var(--bs-warning);
  }
}
</style>