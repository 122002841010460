<template>
  <div class="container ">
    <div id="login_row" class="row h-100  align-items-center justify-content-center">



      <form @submit.prevent="login" class="col-md-8 g-3 needs-validation novalidate" >
        <div class="col-md-6 mx-auto text-center mb-4">
          <AppLogo width="120"/>
        </div>
        <div class="col-md-5 mx-auto">
          <label for="validationCustomUsername" class="form-label">Ваш логін</label>
          <div class="input-group has-validation">
            <span class="input-group-text" id="inputGroupPrepend">@</span>
            <input type="text"
                   v-model="username"
                   class="form-control"
                   id="validationCustomUsername"
                   aria-describedby="inputGroupPrepend" required>
            <div class="invalid-feedback">
              Напишіть логін
            </div>
          </div>
        </div>

        <div class="col-md-5 mb-3 mx-auto mt-3">

          <label class="form-label"
                 for="password"
          >
            Пароль
          </label>
          <input id="password"
                 class="form-control"
                 type="password"
                 v-model="password"
                 required
                 placeholder="Напишіть ваш пароль"
          >
        </div>
        <div class="col-md-5 text-center mx-auto mt-3">
          <button type="submit" class="btn btn-success w-50 mx-auto">Увійти</button>
        </div>
      </form>
      <small class="col-md-8 mx-auto">Дані для входу можна отримати у адміністратора</small>
    </div>
  </div>
</template>


<script>
import AppLogo from "@/components/AppLogo.vue";

export default {
  name: "LoginPage",
  components: {AppLogo},
  data(){
    return {
      username: "",
      password : ""
    }
  },
  methods: {
    login: function () {
      let username= this.username
      let password = this.password
      this.$store.dispatch('login', { username, password })
          .then(() => this.$router.push('/'))
          .catch(err => console.log(err))
    }
  }

}
</script>

<style scoped lang="scss">
.container{
  height: 100vh;
}
</style>