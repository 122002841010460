<script>
export default {
  name: "UserBadge",
  props: {
    userName: String,
    userId: String
  },
  computed: {
    isCurrentUser() {
      return this.$store.getters.userData.id === this.userId
    }
  }
}
</script>

<template >
  <span v-if="isCurrentUser" class="badge bg-warning text-dark ">{{ userName }}</span>
  <span v-else class="badge bg-light text-dark">{{ userName }} </span>
</template>


<style scoped lang="scss">

</style>