<script>
import AppLogo from "@/components/AppLogo.vue";
import UserInfoBar from "@/components/UserInfoBar.vue";
import SubscribeNotification from "@/components/parts/SubscribeNotification.vue";

export default {
  name: "NavBar",
  components: {AppLogo,UserInfoBar,SubscribeNotification},
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
    isManager : function(){ return this.$store.getters.isManager},
    isDraftsman: function(){ return this.$store.getters.isDraftsman}
  },
  methods: {

  },
  created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login')
    }

  },
  mounted() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login')
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container align-items-center">
      <router-link class="navbar-brand"  to="/">
        <AppLogo width="90"/>
      </router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li  class="nav-item">
            <router-link class="nav-link pb-0" to="/">Головна</router-link>
          </li>

          <li v-if="isManager || isDraftsman" class="nav-item">
            <router-link class="nav-link pb-0" to="/orders">Список всіх замовлень</router-link>
          </li>
          <li v-if="isManager" class="nav-item">
            <router-link class="nav-link pb-0" to="/adminpanel">Панель адміністратора</router-link>
          </li>



        </ul>
<!--        <form class="d-flex">-->
<!--          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">-->
<!--          <button class="btn btn-outline-success" type="submit">Search</button>-->
<!--        </form>-->
        <UserInfoBar />

        <SubscribeNotification />

      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">

</style>