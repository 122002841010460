import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/AdminView.vue'
import LoginView from '../views/LoginView.vue'
import store from "@/store";
import RegisterView from "@/views/RegisterView.vue";
import OrderView from "@/views/OrderView.vue";
import OrdersView from "@/views/OrdersView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: 'Список замовлень'
    }
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: OrderView,
    meta: {
      requiresAuth: true,
      title: 'Замовлення '
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersView,
    meta: {
      requiresAuth: true,
      title: 'Список замовлень'
    }
  },
  {
    path: '/adminpanel',
    component: AdminView,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: 'Панель адміністратора'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
      title: 'Вхід'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      requiresAuth: false,
      title: 'Реєстрація'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title +  " - patterns BRAWIX" || 'CRM - patterns BRAWIX ';

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isManager) {
      next()
      return
    }
    next('/')

  }else {
    next()
  }
})

export default router
