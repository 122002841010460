<template>
  <template v-if="isLoggedIn">
    <NavBar/>
  </template>
  <router-view/>
  <UserNotifications :order="orderId"/>
</template>


<script>
import NavBar from "@/components/NavBar.vue";
import UserNotifications from "@/components/UserNotifications.vue";

export default {
  components: {NavBar,UserNotifications},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    orderId: function () {
      return this.$route.params.id ?? null
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    }
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login')
    } else {
      await this.$store.dispatch('fetchUserData')
    }

  },
  mounted() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">

</style>
