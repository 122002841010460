<script>

import ChatMessage from "@/components/order-chat/ChatMessage.vue";

export default {
  name: "ChatPage",
  components: {ChatMessage},
  data() {
    return {
      roomId: this.$route.params.id ?? '',
      newMessage: '',
      newImage: null,
      messages: [],
      usersOfRoom: [],
      uploadUrl: '/api/upload',
      uploadHeaders: {'Authorization': this.$store.getters.userToken},
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userData;
    }
  },
  created() {
    this.$socket.on('chats', (data) => {
      this.messages.push(data);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    });
    this.$socket.on('updateChatUsersList', (users) => {
      this.usersOfRoom = users;
    });
  },
  mounted() {
    this.usersOfRoom.push(this.userInfo)
    this.$socket.emit('joinRoom', {roomId: this.roomId});
    this.$http.get("/api/messages/" + this.roomId)
        .then((res) => {
          this.messages = res.data
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }).catch(e => {
          console.log(e)
        }
    )
  },
  unmounted() {
    this.$socket.emit('leaveRoom', {roomId: this.roomId});

  },
  methods: {
    sendMessage() {
      if (!this.newMessage.trim() && !this.newImage) return;

      this.$socket.emit('chats', this.roomId, {user: this.userInfo, text: this.newMessage,imageId: this.newImage?.id});
      this.newMessage = '';
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      this.deleteImage()
    },
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      if (!container) return;
      container.scrollTop = container.scrollHeight;
    },
    imageFilesUpload: function (fileRecordsNewlySelected) {
      fileRecordsNewlySelected.forEach(file => {
        this.newImage= {
          id: file.data._id,
          type: file.data.type,
          name: file.data.fileName,
          url: "/" + file.data.path,
          size: file.data.size
        }
      })
    },
    deleteImage() {
      this.newImage = null
    }
  }
}
</script>

<template>
  <div id="chatContainer">
    <div class="d-flex justify-content-center w-100 mx-0">
      <div class="wrapper w-100">
        <div class="main">

          <div ref="messageContainer" class="px-3 scroll">
            <ChatMessage v-for="message in messages" :key="message._id"
                         :msgUser="message.user"
                         :msg="message.text"
                         :image="message.image"
                         :time="message.createdAt"/>
          </div>

          <nav class="navbar bg-white navbar-expand-sm d-flex justify-content-between px-2">

            <div class="row align-items-center justify-content-between w-100">
              <div class="col-md-7">
               <textarea v-model="newMessage"
                         @keyup.enter="sendMessage"
                         name="text"
                         class="form-control w-100"
                         placeholder="Напишіть повідомлення...">
              </textarea>
              </div>
              <div class="col-md-3 col-8 d-flex ">
                <button class="btn btn-warning text-center w-100" @click="sendMessage">
                  Надіслати
                </button>
              </div>
              <div class="col-md-2 d-flex justify-content-end align-items-center col-4">
                <VueFileAgent
                    ref="profilePicRef"
                    :auto="true"
                    :uploadUrl="uploadUrl"
                    :uploadHeaders="uploadHeaders"
                    :multiple="false"
                    :deletable="true"
                    :meta="false"
                    :compact="true"
                    :accept="'image/*,video/*'"
                    :helpText="' '"
                    :errorText="{type: 'Завантажити можна лише картинку!',}"
                    :rawModelValue="newImage"
                    @upload="imageFilesUpload($event)"
                    @delete="deleteImage()"
                >


                </VueFileAgent>
              </div>

            </div>

          </nav>
        </div>
      </div>
    </div>
    Замовлення переглядають:
    <ul class="list-group list-group-numbered">
      <li v-for="(user,index) in usersOfRoom" :key="index">
        <span class="badge bg-success">
           {{ user.login }}
        </span>

      </li>
    </ul>


  </div>


</template>

<style scoped lang="scss">


.main {
  background-color: #eee;
  width: 100%;
  position: relative;
  padding: 6px 0 0 0
}

.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 60vh
}


.between {
  font-size: 8px;
  font-weight: 500;
  color: #a09e9e
}

.navbar {

  border: 1px solid rgba(51, 51, 51, 0.44);
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.form-control {
  font-size: 12px;
  font-weight: 400;
  width: 230px;
  height: 30px;
  border: none
}


.form-control:focus {
  box-shadow: none !important
}

</style>

<style lang="scss">
#chatContainer{
  .grid-block-wrapper .grid-block {

    min-width: 50px!important;
    .file-preview-img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>