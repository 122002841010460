<script>
import FilmCutForm from "@/components/forms/FilmCutForm.vue";
import PopOverNoteItem from "@/components/items/PopOverNoteItem.vue";
import {Fancybox} from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
  name: "FilmCutsEditor",
  components: {FilmCutForm, PopOverNoteItem},
  data() {
    return {
      localModelValue: this.modelValue,
      fancyBoxOptions: {
        Carousel: {
          infinite: false
        },
        Toolbar: {
          items: {
            customPrint: {
              tpl: '<button class="f-button"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-printer" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n' +
                  '  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n' +
                  '  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />\n' +
                  '  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />\n' +
                  '  <path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" />\n' +
                  '</svg></button>',
              click: () => {
                const image = Fancybox.getSlide().src;
                const printWindow = window.open('','_blank',"width=500,height=500");
                printWindow.document.write(`<html lang="uk"><head><title>Друк картинки</title></head><body><img alt="cut-image" src="${image}" onload="window.print();window.close()" /></body></html>`);
                printWindow.document.close();
              },
            },
          },
          display: {
            left: ['infobar'],
            middle: [],
            right: [
              'customPrint',
              'slideshow',
              'download',
              'thumbs',
              'close',

            ],
          },
        },
      }
    }
  },
  emits: ['update:modelValue', 'cutsChange'],
  props: {
    modelValue: Array,
  },
  watch: {
    modelValue(newVal) {
      this.localModelValue = newVal;
    }
  },
  methods: {

    getCutData(data) {
      if (data.index) {
        this.localModelValue[data.index] = data
      } else {
        let cutData = data;
        cutData.index = this.localModelValue.length
        this.localModelValue.push(cutData);
      }
      this.$emit('update:modelValue', this.localModelValue);
      this.$emit('cutsChange', 'cuts', this.localModelValue);

    },
    roundFilmSize(num) {
      if (!num) {
        return 0
      }
      if (parseFloat(num) === 0.76) {
        return 0.75
      }
      if (parseFloat(num) === 1.83) {
        return 1.8
      }
      return Math.round(num * 10) / 10;
    },
    removeCut(index) {
      this.$swal.fire({
        title: 'Дійсно видалити відрізок?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Ні',
      }).then((result) => {

        if (result.isConfirmed) {
          const cutData = this.localModelValue[index]
          if (cutData.imageId) {

            this.$http.delete(`/api/upload/${cutData.imageId}`).then(() => {
            }).catch((e) => {
              console.error(e)

            })

            this.localModelValue.splice(index, 1);
            this.$swal.fire(
                'Відрізок видалено',
                '',
                'success',
            )
            return
          }

          this.localModelValue.splice(index, 1);
          this.$swal.fire(
              'Відрізок видалено',
              '',
              'success',
          )

        }
      })
    },
  },
  mounted() {
    Fancybox.bind(this.$refs.filmCuts, '[data-fancybox="cutImageZoom"]', this.fancyBoxOptions);
  },
  updated() {
    Fancybox.unbind(this.$refs.filmCuts);
    Fancybox.close();
    Fancybox.bind(this.$refs.filmCuts, '[data-fancybox="cutImageZoom"]', this.fancyBoxOptions);
  },
  beforeUnmount() {
    Fancybox.destroy();
  },
  computed: {
    totalFilmSize: function () {
      let temp = 0
      this.localModelValue.forEach(item => {
        item.filmSize = this.roundFilmSize(item.width) * this.roundFilmSize(item.height)
        temp += item.filmSize
      })
      return parseFloat(temp).toFixed(2)
    }
  }
}
</script>

<template>
  <div id="filmCuts" ref="filmCuts" class="row align-items-center justify-content-start mt-3">
    <h5 class="col-12">Відрізки плівки для замовлення</h5>
    <div v-for="(item,index) in localModelValue" :key="index" class="col-md-4 col-6 mt-3 mb-4">
      <div :style="{ backgroundImage: 'url(' + item.path ?? ''  + ')' }"
           class="filmCutsItem w-100 d-flex align-items-center justify-content-center">
        <strong class="cutIndex"
                data-bs-toggle="modal"
                data-bs-target="#editCutModal"
                :data-index="index"
                :data-width="item.width"
                :data-height="item.height"
                :data-note="item.note"
                :data-image-id="item.imageId"
                :data-path="item.path ?? null"
        >
          {{ index + 1 }}
        </strong>
        <span @click="removeCut(index)" class="btn-close btn"></span>
        <span class="filmWidth cut-item_size"><strong>{{ item.width }}</strong></span>
        <span class="filmHeight cut-item_size"><strong>{{ item.height }}</strong> </span>
        <div class="cutData p-1">

          <ul class="p-0 m-0">
            <li><strong>Ширина:</strong> {{ item.width }}</li>
            <li><strong>Довжина:</strong> {{ item.height }}</li>
          </ul>

        </div>
        <a :data-fancybox="'cutImageZoom'"
           :data-src="item.path"
           class="cutImageZoom position-absolute  "
           :href="item.path" v-if="item.path">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-photo-search" width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M15 8h.01" />
            <path d="M11.5 21h-5.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5" />
            <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            <path d="M20.2 20.2l1.8 1.8" />
            <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l2 2" />
          </svg>
        </a>
        <PopOverNoteItem v-if="item.note" v-model="item.note"/>
      </div>
    </div>

    <FilmCutForm @getCutData="getCutData"/>

    <div class="col-12">
      <span class="mt-3 badge bg-info-subtle text-dark col-auto p-2">
      Загальна площа плівки: <span class="ps-2">{{ this.totalFilmSize }} М<sup>2</sup></span>
    </span>
    </div>


  </div>

</template>

<style lang="scss">
.filmCutsItem {
  border: 1px solid black;
  height: 115px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .btn-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .cut-item_size {
    background-color: transparent;
    color: #1b1a1a;
    font-size: 10px;
    padding: 5px 2px;
  }

  .filmWidth {
    left: -20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    writing-mode: vertical-lr;

    &:before {
      content: "  ⤌  ";
      padding: 7px;
    }

    &:after {
      content: "  ⤏  ";
      padding: 7px;
    }
  }

  .filmHeight {
    bottom: -25px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    white-space: nowrap;

    &:before {
      content: "  ⤌  ";
      padding-right: 15px;
    }

    &:after {
      content: "  ⤏  ";
      padding-left: 15px;
    }
  }

  .cutIndex {
    background-color: red;
    padding: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bs-tertiary-color);
    color: #fff;
  }

  .cutData {
    position: absolute;
    left: 10px;
    top: 0;
    background-color: rgba(255, 255, 255, 0.29);

    ul {
      list-style: none;
      font-size: 12px;
    }
  }

  .cutImageZoom{
    right: 10px;
    bottom: 10px;
  }

}


</style>