<script setup>
import { ref,reactive, onMounted, onUnmounted,getCurrentInstance } from 'vue';
import Swal from 'sweetalert2';
import $http from "@/axios.config";
import store from "@/store";
import OrderForm from "@/components/forms/OrderForm.vue";
import OrderInWork from "@/components/items/OrderInWork.vue";
import texts from '/./texsts.js';
const closeButton = ref(null);
const activeOrder = reactive({});
const orders = reactive([]);
const ordersInWork = reactive([]);
const ordersInDone = reactive([]);
const instance = getCurrentInstance();

function closeModal() {
  if (closeButton.value) {
    closeButton.value.click();
  }
}
function postOrder(data){

  $http.post('api/orders', data).then(()=>{
    Swal.fire({
      icon: 'success',
      title: texts.titles.success,
      text: texts.messages.order_created,
    });
    closeModal();
  }).catch(e=>{
   console.error(e)
  })
}
onMounted(() => {
  const socket = instance.appContext.config.globalProperties.$socket;
  getNewOrders()
  getOrdersInWork()
  getOrdersInDone()
  socket.on("update-order", () => {
    getNewOrders()
    getOrdersInWork()
    getOrdersInDone()
  });
})
onUnmounted(() => {
  const socket = instance.appContext.config.globalProperties.$socket;
  socket.off("update-order");

});
/*eslint-disable */
function getOrderData(id){
  $http.get('api/orders/' + id).then((response) => {
    activeOrder.value = response.data
  })
}
/*eslint-enable */

function getNewOrders(){
  $http.get('api/orders/status/active').then((response) => {
    orders.value = response.data
  })
}
function getOrdersInWork(){
  $http.get('api/orders/status/work').then((response) => {
    ordersInWork.value = response.data
  })
}
function getOrdersInDone(){
  $http.get('api/orders/status/done',{
    params:{
      sort:{
        updatedAt: -1,
        isUrgent: -1
      }
    }
  }).then((response) => {
    ordersInDone.value = response.data
  })
}
</script>

<template>
<div class="container mt-3">
  <div class="row mb-4 justify-content-center">
    <button
        v-if="store.getters.isManager"
        type="button"
        class="btn btn-outline-success my-3 d-block mx-auto mx-md-1 col-12"
        data-bs-toggle="modal" data-bs-target="#orderModal">
      +
      Додати замовлення
    </button>

    <div class="col-md-4">
      <h3 class="col-12 text-center">
        Нові замовлення
      </h3>

      <div class="list-group mt-3">
        <OrderInWork v-for="order in orders.value" :key="order.id" :order="order"/>
      </div>

    </div>

    <div class=" col-md-4">
      <h3 class="col-12 text-center">
        Замовлення в роботі
      </h3>
      <div class="list-group mt-3 row">
        <OrderInWork v-for="order in ordersInWork.value" :key="order.id" :order="order"/>
      </div>
    </div>
    <div class=" col-md-4">
      <h3 class="col-12 text-center">
        Завершені замовлення
      </h3>
      <div class="list-group mt-3">
        <OrderInWork v-for="order in ordersInDone.value" :key="order.id" :order="order"/>
      </div>
    </div>
  </div>



  <!-- Modal -->
  <div class="modal fade" id="orderModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="orderModalLabel">
            <span v-if="activeOrder.id">Редагувати замовлення</span>
            <span v-else>Додати замовлення</span>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeButton" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <OrderForm  @submitOrder="postOrder"/>
        </div>
      </div>
    </div>
  </div>



</div>
</template>

<style scoped lang="scss">

</style>