// axios.js
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import Swal from 'sweetalert2';
import texts from '../texsts.js';

const instance = axios.create();

instance.interceptors.response.use(response => response, error => {
    if (error.response) {
        const errorMessage = error.response.data.message || 'An unknown error occurred.';

        if (error.response.status === 401) {
            store.dispatch('logout').then(() => {
                Swal.fire({
                    title: texts.titles.alert,
                    text: errorMessage,
                    icon: 'info'
                }).then(() => {
                    router.push('/login');
                });

            });
        } else if (error.response.status === 403) {
            Swal.fire({
                title: texts.titles.alert,
                text: errorMessage,
                icon: 'warning'
            });
        }
    }

    return Promise.reject(error);
});


export default instance;
