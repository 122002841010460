<template>
  <div class="file-upload position-relative">

    <div
        v-if="!hasImage"
        @dragover.prevent
        @dragenter.prevent
        @dragstart.prevent
        @drop.prevent="handleFileChange($event.dataTransfer)"
        class="file-upload__area position-relative">
      <svg xmlns="http://www.w3.org/2000/svg"
           class="icon icon-tabler icon-tabler-upload"
           width="44" height="44" viewBox="0 0 24 24"
           stroke-width="1.5"
           stroke="#2c3e50" fill="none"
           stroke-linecap="round"
           stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"/>
        <path d="M7 9l5 -5l5 5"/>
        <path d="M12 4l0 12"/>
      </svg>
      <div class="position-absolute end-0 top-0 w-100 h-100">
        <input type="file"
               ref="fileInput"
               @change="handleFileChange($event.target)"
        />

        <div v-if="errors.length > 0" class="file-upload__error">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </div>
    </div>

    <div ref="cutImage" v-else class="upload-preview d-flex">
      <button type="button" class="btn btn-outline-danger p-2 position-absolute end-0 top-0 p-1"
              @click="resetFileInput($event)">
        X
      </button>
      <img :data-fancybox="'cutImages'"  v-if="file.isImage" :src="file.url" class="mw-100 h-100 mh-100 object-fit-cover"
           alt="Uploaded Image"/>
      <img  :data-fancybox="'cutImages'" v-if="hasImage" class="mw-100 h-100 mh-100 object-fit-cover" :src="'/' + file.path" alt="img">
      <div class="button-container d-none">
        <button @click="sendDataToParent">Emit file</button>
      </div>
    </div>
  </div>
</template>

<script>
import {Fancybox} from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
  name: "ImageUpload",
  props: {
    maxSize: {
      type: Number,
      default: 5,
      required: true,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    imageId: {
      type: String,
      default: "",
    },

  },
  data() {
    return {
      errors: [],
      file: {
        name: "",
        size: 0,
        type: "",
        fileExtention: "",
        url: "",
        isImage: false,
        isUploaded: false,
        patch: ""
      },
    };
  },
  computed: {
    hasImage: function () {
      return (this.imageId && this.imageId !== 1) ?? false
    }
  },

  mounted() {
    if (this.imageId && this.imageId !== 1) {
      this.file.isUploaded = true
      this.$http.get(`/api/files/${this.imageId}`).then(response => {
        if (response.data) {
          this.file = response.data
          this.$emit("file-uploaded", response.data);
        } else {
          this.file.isUploaded = false
          this.$emit("file-uploaded", {_id: null});
        }
      }).catch(error => {
        console.error('Помилка отримання зображення:', error);
      });
    }
    //document.addEventListener('paste', this.handlePaste);

    Fancybox.bind(this.$refs.cutImage, '[data-fancybox]', {
      ...({}),
    });

  },

  updated() {
    Fancybox.unbind(this.$refs.cutImage);
    Fancybox.close();

    Fancybox.bind(this.$refs.cutImage, '[data-fancybox]', {
      ...({}),
    });
  },
  beforeUnmount() {
    Fancybox.destroy();
    // Прибирання обробника подій при знищенні компонента
    //document.removeEventListener('paste', this.handlePaste);
  },
  methods: {
    handleFileChange(e) {
      const file = e.files[0];
      if (!file) return;

      this.errors = [];
      if (this.isFileValid(file)) {
        this.loadFile(file);
      }

    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const items = clipboardData.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          console.log(item.type, item, item.type.indexOf('image'))
          if (item.type.indexOf('image') === 0) {
            const file = item.getAsFile();
            this.handleFileChange({files: [file]});
          }
        }
      }
    },
    loadFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.file = {
          name: file.name.split(".")[0],
          size: Math.round((file.size / 1024 / 1024) * 100) / 100,
          type: file.type,
          fileExtention: file.name.split(".").pop(),
          isImage: file.type.startsWith("image"),
          url: reader.result,
          isUploaded: true,
        };
      };
      reader.readAsDataURL(file);
      this.uploadFile(file);
    },
    async uploadFile(fileData) {
      const formData = new FormData();
      formData.append('file', fileData);
      this.$http.post('/api/upload', formData).then(response => {
        this.$emit("file-uploaded", response.data);
        this.hasImage = true

      }).catch(error => {
        console.error('Помилка відправлення файлу на сервер:', error);
      });
    },

    resetFileInput(e) {
      e.preventDefault();
      let cf = confirm("Ви впевнені, що хочете видалити зображення?");
      if (!cf) {
        return false;
      }
      this.file.isUploaded = true

      if (this.imageId) {
        this.$http.delete(`/api/upload/${this.imageId}`).then(response => {
          this.$emit("file-uploaded", {_id: null, response});
        }).catch(error => {
          console.error('Помилка видалення зображення:', error);
        });
      }

      this.$nextTick(() => {
        this.file = {
          name: "",
          size: 0,
          type: "",
          data: "",
          fileExtention: "",
          url: "",
          isImage: false,
          isUploaded: false,
        };
        this.hasImage = false
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = ""; // Clear file input
        }
      });
    },
    sendDataToParent() {
      if (this.file.isUploaded) {
        this.$emit("file-uploaded", this.file);
      }
    },
    isFileSizeValid(fileSize) {
      if (fileSize <= this.maxSize) {
        console.log("File size is valid");
        return true;
      } else {
        this.errors.push(`File size should be less than ${this.maxSize} MB`);
        return false;
      }
    },
    isFileTypeValid(fileExtention) {
      if (this.accept.split(",").includes(fileExtention)) {
        console.log("File type is valid");
        return true;
      } else {
        this.errors.push(`File type should be ${this.accept}`);
        return false;
      }
    },
    isFileValid(file) {
      const fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100;
      const fileExtention = file.name.split(".").pop();
      return this.isFileSizeValid(fileSize) && this.isFileTypeValid(fileExtention);
    },
  },
}
;
</script>

<style lang="scss" scoped>
.file-upload {
  height: 250px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__area {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
  }

  &__error {
    margin-top: 10px;
    color: #f00;
    font-size: 12px;
  }

  .upload-preview {
    height: 100%;
    width: 100%;
    justify-content: start;
    flex-direction: column;

  }
}


.upload-preview .file-extension {
  height: 100px;
  width: 100px;
  border-radius: 8px;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em auto;
  font-size: 1.2em;
  padding: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

.upload-preview .file-name {
  font-size: 1.2em;
  font-weight: 500;
  color: #000;
  opacity: 0.5;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  min-height: 100%;
  max-height: 100%;
  cursor: pointer;
}
</style>
