class Helpers {

    convertTime(time) {
        return new Date(time).toLocaleString();
    }

    timeSince(date) {
        const now = new Date();
        const dateObject = new Date(date);
        const diff = now - dateObject;

        const minutes = Math.floor(diff / 60000);
        const hours = Math.floor(diff / 3600000);
        const days = Math.floor(diff / 86400000);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (minutes < 60) {
            return minutes > 1 ? `${minutes} хвилини тому` : `1 хвилину тому`;
        } else if (hours < 24) {
            return hours > 1 ? `${hours} годин тому` : `1 годину тому`;
        } else if (days < 7) {
            return days > 1 ? `${days} дні тому` : `1 день тому`;
        } else if (weeks < 4) {
            return weeks > 1 ? `${weeks} тижні тому` : `1 тиждень тому`;
        } else {
            return months > 1 ? `${months} міс тому` : `1 міс тому`;
        }
    }

    getMinutesSince(date) {
        const now = new Date();
        const dateObject = new Date(date);
        const diff = now - dateObject;
        return Math.floor(diff / 60000);
    }

    customReplace(match, replace, string) {
        const regex = new RegExp(match, 'g');
        return string.replace(regex, replace);
    }

    parseSortParams(sortParams) {
        const parsedSort = {};

        for (const key in sortParams) {
            if (sortParams[key]) {
                const value = parseInt(sortParams[key]);
                if (key in sortParams) {
                    parsedSort[key] = isNaN(value) ? 1 : value;
                }
            }
        }
        return parsedSort;
    }

    getLastCharacters(inputString,length=4) {
        if (!inputString) {
            return '';
        }
        if (inputString.length >= length) {
            return inputString.slice(-length);
        } else {
            return inputString;
        }
    }

    generateCustomId() {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const firstLetter = letters.charAt(Math.floor(Math.random() * letters.length));
        const secondLetter = letters.charAt(Math.floor(Math.random() * letters.length));
        const numbers = Math.floor(Math.random() * 1000).toString().padStart(4, '0');
        return `${firstLetter}${secondLetter}${numbers}`;
    }


}

module.exports = new Helpers()